<template>
  <div>
    <NavbarAneka />
    <ListProductAneka />
    <FooterAneka />
  </div>
</template>

<script>
import NavbarAneka from '@/components/NavbarAneka.vue'
import ListProductAneka from '@/components/ListProductAneka.vue'
import FooterAneka from '@/components/FooterAneka.vue'

export default {
  name: 'ProductView',
  components: {
    NavbarAneka,
    ListProductAneka,
    FooterAneka
  }
}
</script>
