<template>
  <div>
    <NavbarAneka />
    <DetailProduct />
    <FooterAneka />
  </div>
</template>

<script>
import NavbarAneka from '@/components/NavbarAneka.vue'
import DetailProduct from '@/components/DetailProduct.vue'
import FooterAneka from '@/components/FooterAneka.vue'

export default {
  name: 'ProductDetailView',
  components: {
    NavbarAneka,
    DetailProduct,
    FooterAneka
  }
}
</script>

<style scoped>

</style>
