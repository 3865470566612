<template>
  <section class="detail-product">
    <div class="container-fluid">
      <div class="row">
        <!-- <div class="col-lg-4 col-12 col-md-6 text-lg-right text-md-right container-image">
          <img src="/assets/frame.jpg" alt="" width="300px" class="main-picture">
          <div class="d-flex flex-row justify-content-lg-end justify-content-md-end">
            <div class="bg-dark sub-picture">
              <img src="/assets/frame-2.jpg" alt="" width="90px">
            </div>
            <div class="bg-dark sub-picture">
              <img src="/assets/frame-2.jpg" alt="" width="90px">
            </div>
            <div class="bg-dark sub-picture">
              <img src="/assets/frame-2.jpg" alt="" width="90px">
            </div>
          </div>
        </div> -->
        <!-- <div class="col-lg-8 col-12 col-md-6 container-product-information">
          <p class="category">PIGURA</p>
          <h5 class="product-name">Pigura 8R - Minimalist</h5>
          <p class="description">
            Ukuran Foto : 20 x 25cm <br>
            Bahan : Fiber <br>
            • Bisa digantung <br>
            • Bisa diberdirikan <br>
          </p>
          <p class="price">Rp17.500</p>
          <button class="btn btn-primary btn-admin">Beli Sekarang</button>
          <button class="btn btn-outline-primary btn-tokped">
            <img src="https://cdn.cdnlogo.com/logos/t/23/tokopedia.svg">
            Beli Lewat Tokopedia
          </button>
          <button class="btn btn-outline-primary btn-shopee">
            <img src="https://cdn.cdnlogo.com/logos/s/2/shopee.png">
            Beli Lewat Shoppee
          </button>
        </div> -->
        <div class="col-lg-6 col-12 col-md-6 container-image pl-lg-5">
            <div class="product-pic-zoom">
                <img class="product-big-img" :src="gambar_default" alt="" />
            </div>
            <div class="product-thumbs" v-if="productDetails.galleries.length > 0">
              <carousel class="product-thumbs-track ps-slider" :nav="false" :dots="false">
                  <div v-for="ss in productDetails.galleries" :key="ss.id" class="pt" @click="changeImage(ss.photo)" :class="ss.photo == gambar_default ? 'active': '' ">
                      <img :src="ss.photo" alt="" />
                  </div>
              </carousel>
            </div>
        </div>
        <div class="col-lg-6 col-12 col-md-6 container-product-information">
            <div class="product-details text-left">
                <div class="pd-title">
                    <span class="category">{{ productDetails.category.name }}</span>
                    <h3 class="product-name" v-if="productDetails.qty > 0">{{ productDetails.name }}</h3>
                    <h3 class="product-name" v-else>{{ productDetails.name }} <span style="color: #FF7064">(Stok sedang habis)</span></h3>
                </div>
                <div class="pd-desc">
                    <p v-html="productDetails.description" class="description">
                    </p>
                    <!-- <h4 class="price">Rp{{ Number(productDetails.price).toLocaleString().replace(',', '.') }}</h4> -->
                </div>
                <div class="list_button">
                    <button class="btn btn-primary btn-admin">
                      <a href="https://wa.link/eyoqxp" target="_blank" class="text-white text-decoration-none">Beli Sekarang</a>
                      <!-- Beli Sekarang -->
                    </button>
                    <a v-if="productDetails.tokopedia_link != null" v-bind:href="productDetails.tokopedia_link" target="_blank" class="text-decoration-none">
                      <button class="btn btn-outline-primary btn-tokped" v-if="productDetails.tokopedia_link != null">
                        <img src="https://cdn.cdnlogo.com/logos/t/23/tokopedia.svg">
                        Beli Lewat Tokopedia
                      </button>
                    </a>
                    
                    <a v-if="productDetails.shopee_link != null" v-bind:href="productDetails.shopee_link" target="_blank" class="text-decoration-none">
                      <button class="btn btn-outline-primary btn-shopee" v-if="productDetails.shopee_link != null">
                        <img src="https://cdn.cdnlogo.com/logos/s/2/shopee.png">
                        Beli Lewat Shopee
                      </button>
                    </a>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'
import axios from 'axios'
export default {
  name: 'DetailProduct',
  data() {
    return {
      gambar_default: '',
      productDetails: []
    }
  },
  components: {
    carousel
  },
  methods: {
    changeImage(urlImage){
      this.gambar_default = urlImage;
    },
    setDataPicture(data){
        this.productDetails = data
        this.gambar_default = data.galleries[0].photo
    }
  },
  mounted(){
    axios
        .get("https://admin.anekawarnadigital.com/api/products", {
            params: {
                slug: this.$route.params.slug
            }
        })
        .then(res => (this.setDataPicture(res.data.data)))
        .catch(err => console.log(err))
  }
}
</script>


<style>


</style>