<template>
  <section class="partners">
    <div class="container-fluid bg-dark">
      <div class="row text-center">
        <div class="col-lg-3 col-3">
          <img src="/assets/Fujifilm-Logo.png" alt="" width="250px" height="120px" class="fujifilm-logo">
        </div>
        <div class="col-lg-3 col-3">
          <img src="/assets/fuji-xerox.png" alt="" width="250px" height="50px" class="fuji-xerox-logo">
        </div>
        <div class="col-lg-3 col-3">
          <img src="/assets/epson-logo.png" alt="" width="250px" height="120px" class="epson-logo">
        </div>
        <div class="col-lg-3 col-3">
          <img src="/assets/kisspng-logo-brand-vector-graphics-mimaki-engineering-co--5b814909d22536.9096788415351994978608-removebg-preview.png" alt="" width="250px" height="80px" class="mimaki-logo">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnersAneka"
}
</script>

<style scoped>

</style>