<template>
  <nav class="navbar navbar-expand-lg navbar-light shadow-sm">
    <router-link to="/" class="navbar-brand">
        <img src="/assets/logo.png" alt="" width="300px">
    </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <router-link to="/products" class="nav-link">
            Produk
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/how-to-order" class="nav-link">
            Cara Order
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about-us" class="nav-link">
            Tentang Kami
          </router-link>
        </li>
      </ul>
      <span class="nav-item">
        <a href="https://wa.link/eyoqxp" class="btn btn-outline-primary" target="_blank">
          <i class="fa fa-whatsapp" aria-hidden="true"></i> Hubungi Admin
        </a>
      </span>
    </div>
  </nav>
</template>

<script>
export default{
  name: 'NavbarAneka'
}
</script>

<style scoped>

</style>
