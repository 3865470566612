<template>
  <div class="home">
    <NavbarAneka />
    <JumbotronAneka />
    <StatisticsAneka />
    <WhyUsAneka />
    <ProductAneka />
    <div class="col-lg-12 col-12 mb-lg-3 text-center">
      <router-link to="/products" class="btn btn-primary btn-more m-4">
          Lihat Lebih Banyak
      </router-link>
    </div>
    <PartnersAneka />
    <FooterAneka />
    
      
  </div>
</template>

<script>
import NavbarAneka from '@/components/NavbarAneka.vue'
import JumbotronAneka from '@/components/JumbotronAneka.vue'
import StatisticsAneka from '@/components/StatisticsAneka.vue'
import WhyUsAneka from '@/components/WhyUsAneka.vue'
import ProductAneka from '@/components/ProductAneka.vue'
import PartnersAneka from '@/components/PartnersAneka.vue'
import FooterAneka from '@/components/FooterAneka.vue'

export default {
  name: 'HomeView',
  components: {
    NavbarAneka,
    JumbotronAneka,
    StatisticsAneka,
    WhyUsAneka,
    ProductAneka,
    PartnersAneka,
    FooterAneka
  }
}
</script>
