<template>
  <section class="footer shadow">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 profile">
          <img src="/assets/logo.png" alt="Logo Aneka Warna" class="footer-logo">
          <p>
            Alamat : Jl. Brigjend Katamso 230, Yogyakarta <br>
            Phone : +6274 375800 / +6281326837778 <br>
            Email : anekawarnadigital@gmail.com
          </p>
        </div>
        <div class="col-lg-6 links">
          <h5>Information</h5>
          <router-link to="/products">
            Produk
          </router-link> <br>
          <router-link to="/how-to-order">
            Cara Order
          </router-link> <br>
          <router-link to="/about-us">
            Tentang Kami
          </router-link> <br>
        </div>
        <div class="col-lg-12 icons">
          <a href="https://www.instagram.com/id.anekawarna/" target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
          <a href="https://wa.link/eyoqxp" target="_blank">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
          </a>
          <a href="mailto: anekawarnadigital@gmail.com" target="_blank">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
          </a>
          <a href="https://goo.gl/maps/DnR4RA6NUWA347Jx8" class="location-icon" target="_blank">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-lg-12 copyright">
          <div class="row">
            <div class="col-lg-6 col-12">
              <p class="reserved">
                Copyright &copy; 2022 All rights reserverd | Aneka Warna 
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="attribution float-lg-right">
                Illustration by <a href="https://www.freepik.com/" target="_blank">freepik.com</a> - Icon by <a href="https://www.flaticon.com/" target="_blank">flaticon.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterAneka'
}
</script>

<style scoped>

</style>