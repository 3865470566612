<template>
  <section class="about-us">
    <div class="container-fluid">
      <h3>Tentang Kami</h3>
      <p class="about-us-text">
        Aneka Warna adalah perusahaan percetakan yang berfokus pada percetakan photography. Meskipun demikian kami juga menyediakan berbagai macam produk digital printing 
        seperti print A3+, UV Printing, Laser Cutting / Engrave, dan berbagai macam produk digital lainnya. <br>
        Lebih dari 30 tahun kami dipercaya oleh lebih dari 120 mitra bisnis sebagai percetakan yang mampu
        memberikan hasil yang berkualitas. <br>
        Kedepannya Aneka Warna berharap dapat terus berkembang dan tetap menjadi percetakan yang memberikan hasil dan service terbaik kepada para pelanggan kami.
      </p>
      <p class="about-us-text">
        Email :<b> anekawarnadigital@gmail.com</b> <br>
        Instagram :<b> @id.anekawarna</b> <br>
        Whatsapp :<b> 0813-2683-7778</b>
      </p>
    </div>
    <div class="masonry-container">
      <div class="masonry-box">
        <img src="/assets/About Us - Photo - 1.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Photo 2.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Photo - 3.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Photo 4.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Photo - 5.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 1.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 2.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 3.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 4.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 5.jpg" alt="">
      </div>
      <div class="masonry-box">
        <img src="/assets/About Us - Digital - 6.jpg" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutAneka'
}
</script>

<style scoped>

</style>