<template>
<div class="">
  <section class="filter">
    <div class="container-fluid text-center justify-content-center">
      <div class="row search form-group justify-content-center">
        <div class="col-lg-8 col-12 col-md-12 col-sm-12">
          <input type="text" placeholder="Cari Produk..." class="form-control" v-model="search">
          <i class="icon fa fa-search"></i>
        </div>
      </div>
      <div class="row categories">
        <div class="col-lg-3 col-6 categories-item">
          <button class="btn btn-outline-primary btn-block" :class="{ active: activeCategory === 'All' }" @click="loadCategory('All')">All</button>
        </div>
        <div class="col-lg-3 col-6 categories-item" v-for="category in categories" v-bind:key="category.id">
          <button class="btn btn-outline-primary btn-block" :class="{ active: activeCategory === category.name }" @click="loadCategory(category.name)">{{ category.name }}</button>
        </div>
      </div>
    </div>
  </section>
  <section class="products">
    <div class="container-fluid">
      <h3 class="product-title">Produk</h3>
      <div class="row" v-if="filteredProducts.length > 0">
        <div class="col-lg-3 col-6 col-md-4" v-for="product in pageOfItems" v-bind:key="product.id">
          <router-link v-bind:to="'/products/'+ product.slug">
            <div class="card" :class="( product.qty <= 0 ? 'unavailable': '')">
              <img v-bind:src="product.galleries[0].photo" class="card-img-top">
              <img src="/assets/sold-out.png" class="sold-out-img" alt="..." v-if="product.qty <= 0">
              <div class="card-body text-center">
                <h5 class="card-title">{{ product.category.name }}</h5>
                <p class="card-text">{{ product.name }}</p>
                <!-- <p class="card-text product-price">Rp{{ Number(product.price).toLocaleString().replace(',', '.') }}</p> -->
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 min-vh-100 mt-2">
          
        </div>
      </div>
      <div class="mt-3 text-center text-md-center text-lg-right">
        <jw-pagination :pageSize=16 :items="filteredProducts" @changePage="onChangePage"></jw-pagination>
      </div>
    </div>
  </section>
</div>
  
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProductAneka',
  data() {
    return {
      products: [],
      loaded: false,
      dataExists: false,
      categories: [],
      pageOfItems: [],
      activeCategory: "",
      search: ""
    }
  },
  mounted() {
    axios
      .all([
        axios.get("https://admin.anekawarnadigital.com/api/products", {
          params:{
            limit: 1000
          }
        }),
        axios.get("https://admin.anekawarnadigital.com/api/categories")
      ])
      .then(res => {
        this.products = res[0].data.data.data;
        this.categories = res[1].data.data;
      })
      .catch(err => console.log(err));
    this.loadCategory()
  },
  methods: {
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    loadCategory: function(category){
      if(category === "All"){
        axios
          .get("https://admin.anekawarnadigital.com/api/products", {
            params: {
              limit: 1000
            }
          })
          .then(res => (this.products = res.data.data.data))
          .catch(err => console.log(err));
          this.activeCategory = category;
      }else{
        axios
          .get("https://admin.anekawarnadigital.com/api/products", {
            params: {
              type: category,
              limit: 1000
            }
          })
          .then(res => (this.products = res.data.data.data))
          .catch(err => console.log(err));
          this.activeCategory = category;
      }
      
    }
  },
  computed: {
    filteredProducts: function(){
      return this.products.filter((product) => {
        return product.name.toLowerCase().match(this.search.toLowerCase()); 
      })
    }
  }
}
</script>


<style scoped>

</style>