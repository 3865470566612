<template>
  <section class="jumbotron">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-6 jumbotron-text mt-auto mb-auto">
          <h1>
            Solusi Mudah Untuk <br> 
            Cetak Segala Kebutuhanmu
          </h1>
          <p>
            Cetak Photo, Pigura, Photobook, Album, <br>
            UV Printing dan Produk Digital lainnya
          </p>
          <router-link to="/products" class="btn btn-primary">
            Lihat Produk
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 jumbotron-image">
          <img src="/assets/illustration.png" alt="" height="400px">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'JumbotronAneka'
}
</script>

<style scoped>
</style>