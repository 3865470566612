<template>
  <section class="statistics">
    <div class="container shadow">
      <div class="row text-center">
        <div class="col-lg-4 col-4 statistics-item">
          <h5>100+</h5>
          <p>Varian Produk</p>
        </div>
        <div class="col-lg-4 col-4 statistics-item">
          <h5>210k+</h5>
          <p>Order Terselesaikan</p>
        </div>
        <div class="col-lg-4 col-4 statistics-item">
          <h5>120+</h5>
          <p>Mitra Bisnis</p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'StatisticsAneka'
}
</script>

<style scoped>

</style>