<template>
  <div class="about">
    <NavbarAneka />
    <AboutAneka />
    <FooterAneka />
  </div>
</template>

<script>
import NavbarAneka from '@/components/NavbarAneka.vue'
import AboutAneka from '@/components/AboutAneka.vue'
import FooterAneka from '@/components/FooterAneka.vue'

export default {
  name: 'AboutView',
  components: {
    NavbarAneka,
    AboutAneka,
    FooterAneka
  }
}
</script>
