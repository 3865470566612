<template>
  <section class="why-us">
    <div class="container-fluid">
      <h3>Kenapa memilih kami?</h3>
      <div class="row">
        <div class="col-lg-6 col-12 why-us-item">
          <div class="row">
            <div class="col-lg-2 col-2 why-us-icon">
              <img src="/assets/quality.png" alt="" width="70px">
            </div>
            <div class="col-lg-10 col-10 why-us-text">
              <h5>Kualitas Terjamin</h5>
              <p class="">
                Kualitas produk yang terjamin membuat kami 
                dipercaya lebih dari 30 tahun dan 120 mitra bisnis 
                dalam kebutuhan cetak mereka
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 why-us-item">
          <div class="row">
            <div class="col-lg-2 col-2 why-us-icon">
              <img src="/assets/fast-time.png" alt="" width="90px">
            </div>
            <div class="col-lg-10 col-10 why-us-text">
              <h5>Proses Pengerjaan Cepat</h5>
              <p class="">
                Order yang Anda pesan dapat Anda terima dalam
                1-3 hari atau bahkan di hari yang sama <br>
                *s&k berlaku
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 why-us-item">
          <div class="row">
            <div class="col-lg-2 col-2 why-us-icon">
              <img src="/assets/tap.png" alt="" width="70px">
            </div>
            <div class="col-lg-10 col-10 why-us-text">
              <h5>Cara Order Yang Mudah</h5>
              <p class="">
                Anda dapat memesan produk kami melalui 
                WhatsApp atau E-Commerce pilihan Anda 
                dengan berbagai macam cara pembayaran
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 why-us-item">
          <div class="row">
            <div class="col-lg-2 col-2 why-us-icon">
              <img src="/assets/low-price.png" alt="" width="90px">
            </div>
            <div class="col-lg-10 col-10 why-us-text">
              <h5>Harga Yang Terjangkau</h5>
              <p class="">
                Kami memastikan produk yang kami tawarkan 
                mempunyai nilai yang sepadan atau lebih dari yang 
                Anda bayarkan
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyUsAneka'
}
</script>

<style scoped>

</style>