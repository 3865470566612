import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import  JwPagination from 'jw-vue-pagination'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false
Vue.component('jw-pagination', JwPagination)

Vue.use(VueGtag, {
  config: { id: "G-H68EZJ7SL9" }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
