<template>
  <section class="products">
    <div class="container-fluid">
      <h3 class="product-title">Produk</h3>
      <div class="row">
        <div class="col-lg-3 col-6 col-md-4" v-for="product in products_cetak_foto" v-bind:key="product.id">
          <router-link v-bind:to="'/products/'+ product.slug">
            <div class="card" :class="( product.qty <= 0 ? 'unavailable': '')">
              <img v-bind:src="product.galleries[0].photo" class="card-img-top" alt="...">
              <img src="/assets/sold-out.png" class="sold-out-img" alt="..." v-if="product.qty <= 0">
              <div class="card-body text-center">
                <h5 class="card-title">{{ product.category.name }}</h5>
                <p class="card-text">{{ product.name }}</p>
                <!-- <p class="card-text product-price">Rp{{ Number(product.price).toLocaleString().replace(',', '.') }}</p> -->
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-6 col-md-4" v-for="product in products_paket" v-bind:key="product.id">
          <router-link v-bind:to="'/products/'+ product.slug">
            <div class="card">
              <img v-bind:src="product.galleries[0].photo" class="card-img-top" alt="...">
              <div class="card-body text-center">
                <h5 class="card-title">{{ product.category.name }}</h5>
                <p class="card-text">{{ product.name }}</p>
                <!-- <p class="card-text product-price">Rp{{ Number(product.price).toLocaleString().replace(',', '.') }}</p> -->
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-6 col-md-4" v-for="product in products_wedding_book" v-bind:key="product.id">
          <router-link v-bind:to="'/products/'+ product.slug">
            <div class="card">
              <img v-bind:src="product.galleries[0].photo" class="card-img-top" alt="...">
              <div class="card-body text-center">
                <h5 class="card-title">{{ product.category.name }}</h5>
                <p class="card-text">{{ product.name }}</p>
                <!-- <p class="card-text product-price">Rp{{ Number(product.price).toLocaleString().replace(',', '.') }}</p> -->
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-3 col-6 col-md-4" v-for="product in products_digital" v-bind:key="product.id">
          <router-link v-bind:to="'/products/'+ product.slug">
            <div class="card">
              <img v-bind:src="product.galleries[0].photo" class="card-img-top" alt="...">
              <div class="card-body text-center">
                <h5 class="card-title">{{ product.category.name }}</h5>
                <p class="card-text">{{ product.name }}</p>
                <!-- <p class="card-text product-price">Rp{{ Number(product.price).toLocaleString().replace(',', '.') }}</p> -->
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProductAneka',
  data() {
    return {
      products_cetak_foto: [],
      products_paket: [],
      products_wedding_book: [],
      products_digital: []
    }
  },
  mounted() {
    axios
      .all([
        axios.get("https://admin.anekawarnadigital.com/api/products?type=Cetak Foto&limit=4"),
        axios
          .get("https://admin.anekawarnadigital.com/api/products", {
            params: {
              type: "Paket Cetak + Pigura",
              limit: 4
            }
          }), 
        axios.get("https://admin.anekawarnadigital.com/api/products?type=Photobook / Wedding Book&limit=4"), 
        axios.get("https://admin.anekawarnadigital.com/api/products?type=Digital Printing&limit=4") 
      ])
      .then(res => {
        this.products_cetak_foto = res[0].data.data.data;
        this.products_paket = res[1].data.data.data;
        this.products_wedding_book = res[2].data.data.data;
        this.products_digital = res[3].data.data.data;
      })
      .catch(err => console.log(err))
  }
}
</script>


<style scoped>

</style>