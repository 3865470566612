<template>
  <section class="how-to-order">
    <div class="container-fluid">
      <h3 class="title">Cara Order</h3>
      <div class="item d-flex">
        <div class="">
          <img src="/assets/choose.png" alt="" class="item-img">
        </div>
        <div class="">
          <p class="item-description">1. Pilih Produk yang ingin dipesan</p>
        </div>
      </div>
      <div class="item d-flex">
        <div class="">
          <img src="/assets/message.png" alt="" class="item-img">
        </div>
        <div class="">
          <p class="item-description">2. Menghubungi nomor Whatsapp admin kami untuk cek ketersediaan produk, konfirmasi pesanan, dan pengiriman file</p>
        </div>
      </div>
      <div class="item d-flex">
        <div class="">
          <img src="/assets/cashless-payment.png" alt="" class="item-img">
        </div>
        <div class="">
          <p class="item-description">3. Menyelesaikan pembayaran melalui transfer BCA / QRIS</p>
        </div>
      </div>
      <div class="item d-flex">
        <div class="">
          <img src="/assets/pickup.png" alt="" class="item-img">
        </div>
        <div class="">
          <p class="item-description">4. Pesanan siap diambil sesuai waktu yang telah disepakati</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OrderAneka'
}
</script>

<style scoped>

</style>
