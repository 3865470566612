import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import OrderView from '../views/OrderView.vue'
import ProductView from '../views/ProductView.vue'
import ProductDetailView from '../views/ProductDetailView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Aneka Warna - Home'
    }
  },
  {
    path: '/about-us',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    meta: {
      title: 'Aneka Warna - Tentang Kami'
    }
  },
  {
    path: '/how-to-order',
    name: 'how-to-order',
    component: OrderView,
    meta: {
      title: 'Aneka Warna - Cara Order'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: ProductView,
    meta: {
      title: 'Aneka Warna - Daftar Produk'
    }
  },
  {
    path: '/products/:slug',
    props: true,
    name: 'product-detail',
    component: ProductDetailView,
    meta: {
      title: 'Aneka Warna - Detail Produk'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${ to.meta.title }`
  next()
})

export default router
